import React, {FC, useEffect, useState} from "react";
import {Box, IconButton, TextareaAutosize, Typography} from "@mui/material";
import {SessionFlashCard} from "../flashcard/flashcard.type";
import {makeStyles} from "@mui/styles";
import Modal from "@mui/material/Modal";
import {blueGrey} from "@mui/material/colors";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import {TranslateCommand} from "../translation/translation.type";
import TranslationService from "../translation/translation.service";

const useStyles = makeStyles((theme) => ({
    TranslateButton: {

    },
    Modal: {
        position: 'absolute',
        top: '35%',
        left: '15%',
        right: '15%',
        margin: "auto",
        padding: 10,
        backgroundColor: "white",
        border: '2px solid #000',
    },
}));
interface TranslateButtonProps {
    card: SessionFlashCard,
}

const TranslateButton: FC<TranslateButtonProps> = (
    {
        card,
    }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const [translateTextFront, setTranslateTextFront] = useState<string>(card.frontSide);
    const [translateTextBack, setTranslateTextBack] = useState<string>(card.backSide);
    const onTextChangeFront = (e: any) => setTranslateTextFront(e.target.value);
    const onTextChangeBack = (e: any) => setTranslateTextBack(e.target.value);
    const [translatedText, setTranslatedText] = useState<string>("");

    useEffect(() => {
        setTranslateTextFront(card.frontSide)  //todo why useState not change this value?
        setTranslateTextBack(card.backSide)  //todo why useState not change this value?
        setTranslatedText("")  //todo why useState not change this value?
    }, [card]);

    function translate(text: string) {
        const command: TranslateCommand = {
            text: text,
            targetLang: 'pl'
        };
        TranslationService.translate(command)
            .then(function (resp) {

                setTranslatedText(resp.data.targetSentence)
            })
            .catch(function (err) {
                window.location.reload();
            })
    }
    return (
        <React.Fragment>
            <IconButton onClick={() => {
                handleOpen()
            }}>
                <GTranslateIcon color="secondary"/>
            </IconButton>
            <Modal
                open={open}
                onClose={() => {setOpen(false);}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.Modal}>
                    <div>
                        <div>
                            <TextareaAutosize
                                onChange={onTextChangeFront}
                                defaultValue={translateTextFront}/>
                            <IconButton onClick={() => {
                                translate(translateTextFront)
                            }}
                            >
                                <GTranslateIcon sx={{color: blueGrey[900]}}/>
                            </IconButton>
                        </div>
                        <div>
                            <TextareaAutosize
                                onChange={onTextChangeBack}
                                defaultValue={translateTextBack}/>
                            <IconButton onClick={() => {
                                translate(translateTextBack)
                            }}
                            >
                                <GTranslateIcon sx={{color: blueGrey[500]}}/>
                            </IconButton>
                        </div>
                        <Typography id="modal-modal-title" variant="h6" component="h2" color={"black"}>
                            {translatedText}
                        </Typography>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    )
}

export default TranslateButton
