import {NewCardWithDetailSession} from "./flashcard.type";
import {SessionFlashCardActions, SessionFlashCardActionTypes} from "./flashcard.actions";
import {IAppState} from "../Store";

export interface SessionFlashCardState {
    card: NewCardWithDetailSession | null
    loading: boolean
}

const initialState: SessionFlashCardState = {
    card: null,
    loading: false
}
export function cardReducer(state = initialState, action: SessionFlashCardActions): SessionFlashCardState {
    switch (action.type) {
        case SessionFlashCardActionTypes.GET_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }
        case SessionFlashCardActionTypes.GET_SUCCESS: {
            return {
                ...state,
                card: action.card,
                loading: true,
            }
        }
        default:
            return state;
    }
}

const getCardSelector = (state: IAppState) => state.card
