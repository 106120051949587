import React, {FC} from "react";
import {Box, Button, InputLabel, OutlinedInput} from "@mui/material";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import Select from "react-select";
import {connect} from "react-redux";
import {Note, NoteType} from "../note.type";
import NotesService from "../notes.service";

interface EditNoteFormProps {
    note: Note,
    close: () => void,
    setNotesIterate: React.Dispatch<React.SetStateAction<number>>,
}

interface IFormInput {
    text: string,
    type: { label: string; value: NoteType }
}

const EditNoteForm: FC<EditNoteFormProps> = (
    {
        note,
        close = (): void => {},
        setNotesIterate
    }) => {

    const {control, handleSubmit} = useForm<IFormInput>();

    const onSubmit: SubmitHandler<IFormInput> = data => {
        console.log(note)
        console.log(data)
        const newNote = Note.create(
            {
                id: note.id,
                cardId: note.cardId,
                text: data.text,
                type: data.type.value,
            }
        )
        NotesService.change(newNote)
            .then(function (resp) {
                setNotesIterate((c) => c + 1)
                close()
            })
            .catch(function (err) {
                console.log(err)
            })

    };


    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="text"
                    control={control}
                    defaultValue={note.text}
                    render={({field}) =>
                        <Box>
                            <InputLabel htmlFor="outlined-adornment-amount">Front</InputLabel>
                            <OutlinedInput  {...field} fullWidth sx={{m: 1}}/>
                        </Box>}
                />
                <Controller
                    name="type"
                    control={control}
                    defaultValue={{value: note.type, label: note.type.valueOf()}}
                    render={({field}) =>
                        <Box pt={2} pb={2}>
                            <InputLabel htmlFor="outlined-adornment-amount">TYPE</InputLabel>
                            <Select
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: 'blue',
                                        primary25: '#E9B5B5',
                                        neutral0: 'black',
                                        neutral80: 'white',
                                    },
                                })}
                                {...field}
                                isSearchable={false}
                                options={[
                                    {value: NoteType.CARD, label: NoteType.CARD.valueOf()},
                                    {value: NoteType.HARD_CARD, label: NoteType.HARD_CARD.valueOf()},
                                    {value: NoteType.GRAM, label: NoteType.GRAM.valueOf()},
                                    {value: NoteType.TO_ASK, label: NoteType.TO_ASK.valueOf()},
                                    {value: NoteType.TO_DO, label: NoteType.TO_DO.valueOf()},
                                    {value: NoteType.SIMPLE, label: NoteType.SIMPLE.valueOf()},
                                ]}
                            />
                        </Box>}
                />

                <Button type="submit" variant="contained" color="primary">
                    Edytuj
                </Button>
            </form>
        </React.Fragment>
    )
}


const EditNoteFormContainer = connect()(EditNoteForm);

export {
    EditNoteFormContainer
}
