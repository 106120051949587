import React, {FC} from "react";
import {Box} from "@mui/material";
import {connect} from 'react-redux';
import Modal from "@mui/material/Modal";
import {makeStyles} from "@mui/styles";
import FlashCardService from "../flashcard.service";
import {Card} from "../flashcard.type";
import {EditCardFormContainer} from "./EditCardForm.component";

const useStyles = makeStyles((theme) => ({
    Modal: {
        position: 'absolute',
        top: '15%',
        left: '15%',
        right: '15%',
        // margin: "auto",
        padding: 10,
        backgroundColor: "white",
        border: '2px solid #000',
    },
}));

interface EditCardModalProps {
    open: boolean,
    cardId: string,
    setClose: () => void,
    getCard: () => void,
}

const EditCardModal: FC<EditCardModalProps> = (
    {
        open,
        cardId,
        setClose = (): void => {},
        getCard = (): void => {},
    }) => {
    const classes = useStyles();


    const [card, setCard] = React.useState<Card | null>(null);
    const [tags, setTags] = React.useState<Array<string>>([]);
    if (open) {
        if (card === null) {
            getCardForEdit()
            getTags()
        }

    }


    function getCardForEdit() {
        FlashCardService.getCard(cardId)
            .then(function (resp) {
                setCard(resp.data)
            })
            .catch(function (err) {
                console.log(err)
            })
    }

    function getTags() {
        FlashCardService.getTags()
            .then(function (resp) {
                setTags(resp.data)
            })
            .catch(function (err) {
                console.log(err)
            })
    }

    function close(){
        setClose();
        setCard(null)
    }

    return (
        <React.Fragment>
            <Modal
                open={open}
                onClose={() => {
                    close()
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.Modal}>
                    {card ? <EditCardFormContainer
                        card={card}
                        allTags={tags}
                        getCard={getCard}
                        close={() => {close()}}
                    /> : <div></div>}
                </Box>
            </Modal>

        </React.Fragment>
    )
}


const EditCardModalContainer = connect()(EditCardModal);

export {
    EditCardModalContainer
}
