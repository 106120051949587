import {Settings} from "./settings.type";
import {SettingTypes, SetVolumeAction} from "./settings.actions";

export interface SettingsState {
    readonly settings: Settings
}

const initialState: SettingsState = {
    settings: Settings.create({
        volume: false,
        hotkey: false,
        onlyNewCard: false
    })
}

export function settingsReducer(state = initialState, action: SetVolumeAction): SettingsState {
    switch (action.type) {
        case SettingTypes.SET_VOLUME: {
            return {
                ...state,
                settings: action.settings,
            }
        }
        default:
            return state;
    }
}