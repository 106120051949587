import React, {FC} from "react";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {IconButton} from "@mui/material";
import {IAppState} from "../Store";
import {connect} from 'react-redux';
import {Settings} from "../setting/settings.type";
import {bindActionCreators, Dispatch} from 'redux';
import {changeSetting} from "../setting/settings.actions";

interface HotKeyOnButtonProps {
    changeSetting: (settings: Settings) => void,
    settings: Settings
}

const HotKeyOnButton: FC<HotKeyOnButtonProps> = (
    {
        changeSetting = (): void => {},
        settings
    }) => {
    return (
        <React.Fragment>
            <IconButton onClick={() => {
                let prevValue = settings.hotkey;
                changeSetting(
                    settings.copy({hotkey: !prevValue}))
            }}>
                {settings.hotkey ? <RadioButtonCheckedIcon color="secondary"/> : <RadioButtonUncheckedIcon color="secondary"/>}
            </IconButton>
        </React.Fragment>
    )
}

const mapStateToProps = (store: IAppState) => {
    return {
        settings: store.settings.settings,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            changeSetting: changeSetting,
        },
        dispatch
    )


const HotKeyOnButtonContainer = connect(mapStateToProps, mapDispatchToProps)(HotKeyOnButton);

export {
    HotKeyOnButtonContainer
}
