import {Box, IconButton, Paper} from "@mui/material";
import React, {FC, useEffect} from "react";
import {Language} from "../flashcard.type";
import {makeStyles} from "@mui/styles";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import {useSpeak} from '../../speak/speak.hook';

const useStyles = makeStyles((theme) => ({
    Side: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 15,
        paddingRight: 15,
    },
}));

interface NextFlashcardSessionSideProps {
    text: string,
    language: Language,
    isHidden: boolean,
    volumeOn: boolean

}

const NextFlashcardSessionSide: FC<NextFlashcardSessionSideProps> = (
    {
        text,
        language,
        isHidden = true,
        volumeOn
    }) => {
    const classes = useStyles();
    const {speak} = useSpeak();

    //hack na to aby czytało front. Normalne uzycie  useEffect(speak) nie przynosi zamierzonego efektu
    //niestety hack z jakiegos powodu nie czyta back. back jest komponent wyzej.
    //podałem sie...
    useEffect(() => {
        const timer = setTimeout(() => {
            if (volumeOn){
                speak(text, language);
            }
        }, 150);
        return () => clearTimeout(timer);
    }, [text, isHidden]);




    return <Box pl={5} pr={5} pt={1} >
        <Paper elevation={10} >
            <p className={classes.Side}>{text}</p>
        </Paper>
        <Box pb={2}>
            <IconButton onClick={() => {
                speak(text)
            }}>
                <VolumeUpIcon color="secondary"/>
            </IconButton>
        </Box>
    </Box>


};
export default NextFlashcardSessionSide
