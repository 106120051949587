import React, {FC} from "react";
import {Box, Button, Checkbox, FormControlLabel, InputLabel, OutlinedInput, Typography} from "@mui/material";
import {Card, EditFLashCardCommand, Language} from "../flashcard.type";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import Select from "react-select";
import FlashCardService from "../flashcard.service";
import {connect} from "react-redux";
import {makeStyles} from "@mui/styles";

interface EditCardFormProps {
    card: Card,
    allTags: Array<string>
    getCard: () => void,
    close: () => void,
}

interface IFormInput {
    front: string
    back: string,
    language: { label: string; value: Language }
    tags: Array<string>
}

const useStyles = makeStyles((theme) => ({
    FrontInput: {
        backgroundColor: "black",
    },
}));

const EditCardForm: FC<EditCardFormProps> = (
    {
        card,
        allTags,
        getCard = (): void => {
        },
        close = (): void => {
        },
    }) => {
    const classes = useStyles();
    const {control, handleSubmit} = useForm<IFormInput>();

    const onSubmit: SubmitHandler<IFormInput> = data => {
        FlashCardService.editCard(EditFLashCardCommand.create({
            cardId: card.id,
            frontSide: data.front,
            backSide: data.back,
            language: data.language.label,
            tags: data.tags
        })).then(function (resp) {
            getCard()
            close()
        })
            .catch(function (err) {
                console.log(err)
            })
    };


    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="front"
                    control={control}
                    defaultValue={card.frontSide}
                    render={({field}) =>
                        <Box>
                            <OutlinedInput  {...field} fullWidth sx={{m: 1, height: 35, fontSize: 14}}/>
                        </Box>}
                />
                <Controller
                    name="back"
                    control={control}
                    defaultValue={card.backSide}
                    render={({field}) =>
                        <Box >
                            <OutlinedInput  {...field}  fullWidth sx={{m: 1, height: 35, fontSize: 14}}/>
                        </Box>}
                />
                <Controller
                    name="language"
                    control={control}
                    defaultValue={{value: card.language, label: card.language.valueOf()}}
                    render={({field}) =>
                        <Box pb={1.5}>
                            <Select
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: 'blue',
                                        primary25: '#E9B5B5',
                                        neutral0: 'black',
                                        neutral80: 'white',
                                    },
                                })}
                                isSearchable={false}
                                {...field}
                                options={[
                                    {value: Language.ENGLISH, label: Language.ENGLISH.valueOf()},
                                    {value: Language.POLISH, label: Language.POLISH.valueOf()}
                                ]}
                            />
                        </Box>}
                />
                <Button type="submit" variant="contained" color="primary">
                    Edytuj
                </Button>
                <Controller
                    name="tags"
                    control={control}
                    defaultValue={card.tags}
                    render={({field}) => (
                        <Box>
                            <InputLabel htmlFor="outlined-adornment-amount">Tags</InputLabel>
                            {
                                allTags.map((tag, idx) => (
                                    <FormControlLabel

                                        key={tag}
                                        label={<Typography color="black" >{tag}</Typography>}
                                        // labe
                                        color={"primary"}
                                        control={
                                            <Checkbox
                                                style={{padding: 4}}
                                                value={tag}
                                                // For some reason codesandbox doesn't support `field.value.includes(...)`
                                                checked={field.value.includes(tag)}
                                                onChange={(event, checked) => {
                                                    if (checked) {
                                                        field.onChange([
                                                            ...field.value,
                                                            event.target.value
                                                        ]);
                                                    } else {
                                                        field.onChange(
                                                            field.value.filter(
                                                                (value) => value !== event.target.value
                                                            )
                                                        );
                                                    }
                                                }}
                                            />
                                        }
                                    />
                                ))}
                        </Box>)}
                />


            </form>
        </React.Fragment>
    )
}


const EditCardFormContainer = connect()(EditCardForm);

export {
    EditCardFormContainer
}
