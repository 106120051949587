import React, {FC} from "react";
import TranslateButton from "./translateButton.component";
import AddNoteButton from "./noteCardButton.component";
import StaticButton from "./StaticButton.component";
import {SessionDetail, SessionFlashCard} from "../flashcard/flashcard.type";
import {makeStyles} from "@mui/styles";
import {VolumeOnButtonContainer} from "./VolumeOnButton.component";
import {bindActionCreators, Dispatch} from "redux";
import {getCard} from "../flashcard/flashcard.actions";
import {connect} from "react-redux";
import {IAppState} from "../Store";
import IgnoreCardButton from "./IgnoreButton.component";
import {EditCardButtonContainer} from "./EditCardButtom.component";
import {OnlyNewCardButtonContainer} from "./OnlyNewCardButton.component";
import {HotKeyOnButtonContainer} from "./HotkeyOnButton.component";

const useStyles = makeStyles((theme) => ({
    ButtonMenu: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-between",
    },
    ButtonMenuLeft: {
        marginTop: "auto",
        marginBottom: "auto",
        // marginLeft: 10,
        ['@media (min-width:780px)']: {
            margin: "auto"
        }

    },
    ButtonMenuRight: {
        ['@media (min-width:780px)']: {
            margin: "auto"
        }
    },
}));

interface MyButtonProps {
    card: SessionFlashCard,
    getCard: () => void,
    sessionDetail: SessionDetail
}

const ButtonMenu: FC<MyButtonProps> = (
    {
        card,
        sessionDetail,
        getCard = (): void => {},
    }) => {

    const classes = useStyles();
    return (
        // <Rea ml={"auto"} pb={7}>
        <div className={classes.ButtonMenu}>
            <div className={classes.ButtonMenuLeft}>
                <EditCardButtonContainer cardId={card.id}/>
            </div>
            <div className={classes.ButtonMenuRight}>
                <OnlyNewCardButtonContainer/>
                <VolumeOnButtonContainer/>
                <HotKeyOnButtonContainer/>
                <IgnoreCardButton card={card} getCard={getCard}/>
                <TranslateButton card={card}/>
                <AddNoteButton card={card}/>
                {sessionDetail && <StaticButton sessionDetail={sessionDetail}/>}
            </div>

        </div>
    );
}

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getCard: getCard
        },
        dispatch
    )

const mapStateToProps = (store: IAppState) => {
        return {
            sessionDetail: store.card.card?.sessionDetail ?? SessionDetail.create(
                {
                    newCardCount: 0,
                    repeatCardCount: 0,
                    rest: 0
                })
        };
    }
;


const ButtonMenuContainer = connect(mapStateToProps, mapDispatchToProps)(ButtonMenu);

export {
    ButtonMenuContainer
}
