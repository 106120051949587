const routes = {
    root: '/',
    translation: id => `/translation/${id}`,
    flashcardBatchAdd: `/flashcard/batch-add`,
    flashcardSession: `/flashcard/session`,
    notes: `/notes`,
    flashcardLearning: `/flashcard/session-learning`,
};

export { routes };
