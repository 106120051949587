import React, {FC, useEffect, useState} from "react";
import {connect} from 'react-redux';
import {reverse, SessionFlashCard, SessionFlashCardsStatus, Touch, TouchCardCommand} from "../flashcard.type";
import FlashCardService from "../flashcard.service";
import MyButton from "../../Button/Button.component";
import NextFlashcardSessionSide from "./nextFlashcardSessionSide.component";
import {makeStyles} from "@mui/styles";
import {Box, Typography} from "@mui/material";
import {ButtonMenuContainer} from "../../Button/ButtoMenu.component";
import {useSpeak} from "../../speak/speak.hook";
import {getCard} from "../flashcard.actions";
import {IAppState} from "../../Store";
import {bindActionCreators, Dispatch} from "redux";
import CheckButton from "./checkButton/checkButton.component";

const useStyles = makeStyles((theme) => ({
    FlashcardsSessionStartMenu: {
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontSize: "calc(10px + 2vmin)",
    },
    Bottom: {
        position: "absolute",
        bottom: 5
    }

}));

interface NextFlashcardSessionProps {
    volumeOn: boolean,
    hotkeyOn: boolean,
    card: SessionFlashCard
    getCard: () => void,
}

const NextFlashcardSession: FC<NextFlashcardSessionProps> = (
    {
        hotkeyOn,
        volumeOn,
        card,
        getCard = (): void => {},
    }) => {
    const [checkCard, setCheckCard] = useState(false);
    const classes = useStyles();
    const {speak} = useSpeak();

    useEffect(() => {
        setCheckCard(false)
    }, [card]);

    function speakBack() {
        volumeOn && speak(card.backSide, reverse(card.language))
    }

    function bottomText(card: SessionFlashCard) {
        if (card.advancementLearning === SessionFlashCardsStatus.REPEAT_CARD) {
            return "Repeat Card, series: ".concat(card.series.toString())
        }
        return "New Card"
    }

    function touchCart(cardId: string, touch: Touch) {
        const command: TouchCardCommand = {
            cardId,
            touch
        };
        FlashCardService.touchCard(command)
            .then(function (resp) {
                setCheckCard(false)
                getCard()
            })
            .catch(function (err) {
                console.log(err)
            })
    }

    function touchCard(cardId: string, touchType: Touch) {
        return () => {
            touchCart(cardId, touchType)
        };
    }

    return <React.Fragment>
        <React.Fragment>
            <ButtonMenuContainer
                card={card}
            />

            <div className={classes.FlashcardsSessionStartMenu}>
                <NextFlashcardSessionSide text={card.frontSide} language={card.language} isHidden={false}
                                          volumeOn={volumeOn}/>
                {checkCard ? <React.Fragment>
                        <Box>
                            <MyButton sx={{mr: 1, minWidth: 130}} variant="contained"
                                      hotkeyEnabled={hotkeyOn}
                                      keyName="num_1,1"
                                      click={touchCard(card.id, Touch.OK)}
                                      unDisabledDelay={700}
                            >Umiem </MyButton>
                            <MyButton sx={{ml: 1, minWidth: 130}} variant="contained"
                                      hotkeyEnabled={hotkeyOn}
                                      keyName="num_2,2"
                                      click={touchCard(card.id, Touch.WRONG)}
                                      color={'error'}
                            >Nie umiem </MyButton>
                        </Box>
                        <Box marginTop={2}>
                            <MyButton sx={{mr: 1, minWidth: 130}} variant="contained"
                                      click={touchCard(card.id, Touch.IS_TO_EASY)}
                                      color={'success'}
                            >Za łatwe</MyButton>
                            <MyButton sx={{ml: 1, minWidth: 130}} variant="contained"
                                      click={touchCard(card.id, Touch.POSTPONEMENT)}
                                      color={'info'}
                                      disabled={card.cannotPostponement || card.advancementLearning === SessionFlashCardsStatus.NEW_CARD}
                            >Odroczenie </MyButton>
                        </Box>
                        <NextFlashcardSessionSide text={card.backSide} language={reverse(card.language)}
                                                  isHidden={!checkCard} volumeOn={volumeOn}/>
                    </React.Fragment>
                    : <CheckButton
                        card={card}
                        hotkeyOn={hotkeyOn}
                        click={() => {
                            speakBack()
                            setCheckCard(true)
                        }}
                    />

                }
                <div className={classes.Bottom}>
                    <Typography fontSize={14}>{bottomText(card)}</Typography>
                </div>

            </div>

        </React.Fragment>
    </React.Fragment>

}

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getCard: getCard
        },
        dispatch
    )

const mapStateToProps = (store: IAppState) => {
    return {
        volumeOn: store.settings.settings.volume,
        hotkeyOn: store.settings.settings.hotkey
    };
};

const NextFlashcardSessionContainer = connect(mapStateToProps, mapDispatchToProps)(NextFlashcardSession);

export {NextFlashcardSessionContainer}
