import React, {FC} from "react";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import {IconButton} from "@mui/material";
import {IAppState} from "../Store";
import {connect} from 'react-redux';
import {Settings} from "../setting/settings.type";
import {bindActionCreators, Dispatch} from 'redux';
import {changeSetting} from "../setting/settings.actions";

interface VolumeOnButtonProps {
    changeSetting: (settings: Settings) => void,
    settings: Settings
}

const VolumeOnButton: FC<VolumeOnButtonProps> = (
    {
        changeSetting = (): void => {},
        settings
    }) => {
    return (
        <React.Fragment>
            <IconButton onClick={() => {
                let prevValue = settings.volume;
                changeSetting(
                    settings.copy({volume: !prevValue}))
            }}>
                {settings.volume ? <VolumeUpIcon color="secondary"/> : <VolumeOffIcon color="secondary"/>}
            </IconButton>
        </React.Fragment>
    )
}

const mapStateToProps = (store: IAppState) => {
    return {
        settings: store.settings.settings,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            changeSetting: changeSetting,
        },
        dispatch
    )


const VolumeOnButtonContainer = connect(mapStateToProps, mapDispatchToProps)(VolumeOnButton);

export {
    VolumeOnButtonContainer
}
