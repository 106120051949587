// @ts-ignore
import {useSpeechSynthesis} from 'react-speech-kit';
import {Language} from "../flashcard/flashcard.type";

export function useSpeak() {

    const {speak: speak2, voices} = useSpeechSynthesis();
    var voice = voices[160]
    if(iOS()){
        voice = voices.filter((option: any) => (option.name === "Samantha"))[0]
    }
    const speak = function speak(text: string, lang: Language = Language.ENGLISH) {
        if (lang === Language.ENGLISH) {
            const textToRead = text
                .replace(/ *\([^)]*\) */g, "")
                .replace(" sth", " something")
                .replace(" sb", " somebody");

            speak2(
                {
                    text: textToRead,
                    rate: 0.9,
                    voice: voice
                }
            )
        }

    };

    return {
        speak: speak,
    };
}

export function iOS() {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}
