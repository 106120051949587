import {Data} from "dataclass";

export class FLashCardCreateCommand extends Data{
    frontSide: string
    backSide: string
    from: string
    tags: Array<string>
}

export interface RawLine {
    id: number,
    text: string,
}

export interface RawLineWithTags {
    id: number,
    text: string,
    tags: Array<string>,
}

export interface Line {
    id: number,
    frontSide: string,
    backSide: string,
    tags: Array<string>,
}

export interface FLashCardBatchAddCommand {
    cards: Array<FLashCardCreateCommand>,
}

export interface TouchCardCommand {
    cardId: string,
    touch: Touch
}

export class EditFLashCardCommand extends Data{
    cardId: string
    frontSide: string
    backSide: string
    language: string
    tags: Array<string>
}

export interface EnrichSession {
    status: SessionFlashCardsStatus,
    count: number
}

export enum Touch {
    OK, WRONG, IS_TO_EASY, POSTPONEMENT
}

export interface Session {
    cards: Array<SessionFlashCard>,
}

export interface NewCardWithDetailSession {
    card: SessionFlashCard,
    sessionDetail: SessionDetail,
}

export class SessionDetail extends Data{
    newCardCount: number = 0;
    repeatCardCount: number = 0;
    rest: number = 0
}

export interface SessionFlashCard {
    id: string,
    frontSide: string,
    backSide: string,
    advancementLearning: SessionFlashCardsStatus,
    series: number,
    language: Language,
    cannotPostponement: boolean,
    tags: Array<string>
}

export class Card extends Data{
    id: string
    frontSide: string
    backSide: string
    language: Language
    tags: Array<string>
}

export class Tag extends Data{
    value: string
}

export enum SessionFlashCardsStatus {
    NEW_CARD = "NEW_CARD",
    REPEAT_CARD = "REPEAT_CARD",
}

export enum Language {
    ENGLISH = "ENGLISH",
    POLISH = "POLISH"
}

export function short(language: Language) {
    if (language == Language.ENGLISH) {
        return "ENG"
    } else {
        return "PL"
    }
}

export function reverse(language: Language) {
    if (language == Language.ENGLISH) {
        return Language.POLISH
    } else {
        return Language.ENGLISH
    }
}
