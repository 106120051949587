import React, {FC} from "react";
import FiberNewIcon from '@mui/icons-material/FiberNew';
import {IconButton} from "@mui/material";
import {IAppState} from "../Store";
import {connect} from 'react-redux';
import {Settings} from "../setting/settings.type";
import {bindActionCreators, Dispatch} from 'redux';
import {changeSetting} from "../setting/settings.actions";

interface OnlyNewCardButtonProps {
    changeSetting: (settings: Settings) => void,
    settings: Settings
}

const OnlyNewCardButton: FC<OnlyNewCardButtonProps> = (
    {
        changeSetting = (): void => {},
        settings
    }) => {
    return (
        <React.Fragment>
            <IconButton onClick={() => {
                let prevValue = settings.onlyNewCard;
                changeSetting(
                    settings.copy({onlyNewCard: !prevValue}))
            }}>
                {settings.onlyNewCard ? <FiberNewIcon color="error"/> : <FiberNewIcon color="secondary"/>}
            </IconButton>
        </React.Fragment>
    )
}

const mapStateToProps = (store: IAppState) => {
    return {
        settings: store.settings.settings,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            changeSetting: changeSetting,
        },
        dispatch
    )

const OnlyNewCardButtonContainer = connect(mapStateToProps, mapDispatchToProps)(OnlyNewCardButton);

export {
    OnlyNewCardButtonContainer
}
