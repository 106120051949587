import {Data} from "dataclass";

export interface AddNoteCommand {
    cardId?: string,
    type: NoteType,
    text: string
}

export class ChangeNoteCommand extends Data {
    id: string
    cardId?: string
    type: NoteType
    text: string
    done: boolean
}

export enum NoteType {
    CARD = 'CARD',
    HARD_CARD = 'HARD_CARD',
    GRAM = 'GRAM',
    TO_ASK = 'TO_ASK',
    TO_DO = 'TO_DO',
    SIMPLE = 'SIMPLE',
}

export class Note extends Data {
    id: string
    cardId?: string
    text: string
    type: NoteType
}
