import {BrowserRouter, Route, Routes} from "react-router-dom";
import Translation from "./translation/translation.component";
import Home from "./home.component";
import {routes as browserRoute} from "./routes";
import FlashcardBatchAdd from "./flashcard/batchAdd/flashcardBatchAdd.component";
import {FlashcardsSessionContainer} from "./flashcard/session/flashcardSession.component";
import {makeStyles} from "@mui/styles";
import {ThemeProvider} from '@mui/material/styles';
import theme from "./styles/muiTheme";
import Notes from "./notes/notes.component";
import {Store} from 'redux';
import {IAppState} from "./Store";

const useStyles = makeStyles((theme) => ({
    App: {},
}));


interface IProps {
    store: Store<IAppState>;
}

const App = () => {
    const classes = useStyles();
    return (

            <ThemeProvider theme={theme}>
                <div className={classes.App}>
                    <header className="App-header">
                    </header>

                    <BrowserRouter>
                        {/*<Navbar></Navbar>*/}
                        <div className="App-intro">
                            <Routes>
                                <Route path="" element={<Home/>}/>
                                <Route path={browserRoute.translation(':id')} element={<Translation/>}/>
                                <Route path={browserRoute.flashcardBatchAdd} element={<FlashcardBatchAdd/>}/>
                                <Route path={browserRoute.flashcardSession} element={<FlashcardsSessionContainer/>}/>
                                <Route path={browserRoute.notes} element={<Notes/>}/>
                            </Routes>
                        </div>

                    </BrowserRouter>
                </div>
            </ThemeProvider>


    );

}


export default App;
