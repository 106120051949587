import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {NoteType, Note} from "./note.type";
import NotesService from "./notes.service";
import {
    Box,
    FormControl,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableContainer
} from "@mui/material";
import NoteRow from "./noterow.component";

const useStyles = makeStyles((theme) => ({
    FormControl: {
        width: 100,
        backgroundColor: 'white',
        ['@media (min-width:780px)']: {
            width: 250
        }
    },
}));


const Notes = () => {
    const classes = useStyles();
    const [notes, setNotes] = useState(new Array<Note>());
    const [notesIterate, setNotesIterate] = useState(0);
    const [noteType, setNoteType] = React.useState(NoteType.CARD);

    const handleChange = (event: SelectChangeEvent) => {
        setNoteType(event.target.value as NoteType);
    };

    useEffect(() => {
        const fetchData = async () => {
            NotesService.get(noteType)
                .then(function (resp) {
                    setNotes(resp.data)
                })
                .catch(function (err) {
                    console.log(err)
                })

        }
        fetchData();
    }, [notesIterate, noteType]);

    return (
        <Box p={8}>

            <FormControl className={classes.FormControl}>

                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={noteType}
                    label="Age"
                    onChange={handleChange}
                >
                    <MenuItem value={NoteType.CARD}>CARD</MenuItem>
                    <MenuItem value={NoteType.HARD_CARD}>HARD_CARD</MenuItem>
                    <MenuItem value={NoteType.GRAM}>GRAM</MenuItem>
                    <MenuItem value={NoteType.TO_ASK}>TO_ASK</MenuItem>
                    <MenuItem value={NoteType.TO_DO}>TO_DO</MenuItem>
                    <MenuItem value={NoteType.SIMPLE}>SIMPLE</MenuItem>
                </Select>
            </FormControl>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        {notes.map((line, idx) => (
                            <NoteRow note={line} setNotesIterate={setNotesIterate}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>

    )
}

export default Notes
