import http from "../http-common";
import {
    Card,
    EditFLashCardCommand,
    EnrichSession,
    FLashCardCreateCommand,
    NewCardWithDetailSession,
    SessionFlashCard,
    SessionFlashCardsStatus,
    TouchCardCommand
} from "./flashcard.type";

export class FlashCardService {

    addCard(command: FLashCardCreateCommand) {
        return http.put("flashcard", command)
    }

    getNextCard(status?: SessionFlashCardsStatus) {
        if (status){
            return http.get<NewCardWithDetailSession>("/flashcard/session/nextCard?status="+status)
        }
        return http.get<NewCardWithDetailSession>("/flashcard/session/nextCard")
    }

    getTags() {
        return http.get<Array<string>>("/tags")
    }

    getCard(cardId: string) {
        return http.get<Card>(`/card/${cardId}`)
    }

    touchCard(command: TouchCardCommand) {

        const sessionId = '35f353a5-f8ab-45a5-98b0-834a4ff0b92f';  // todo many session
        return http.put(`/flashcard/session/${sessionId}/touch-card`, command)
    }

    editCard(command: EditFLashCardCommand) {
        return http.put<Card>("/flashcard/edit", command)
    }

    ignoreCard(cardId: string) {
        return http.put<SessionFlashCard>(`/flashcard/${cardId}/ignore`)
    }

    enrichSession(command: EnrichSession) {
        const sessionDailyId = '35f353a5-f8ab-45a5-98b0-834a4ff0b92f';  // todo many session
        return http.put(`/flashcard/session/${sessionDailyId}?status=${command.status}&count=${command.count}`)
    }


}

export default new FlashCardService();
