import React, {FC} from "react";
import {makeStyles} from "@mui/styles";
import {Note} from "./note.type";
import NotesService from "./notes.service";
import {ButtonGroup, TableCell, TableRow} from "@mui/material";
import MyButton from "../Button/Button.component";
import {EditCardButtonContainer} from "../Button/EditCardButtom.component";
import {EditNoteModalContainer} from "./button/EditNoteModal.component";

const useStyles = makeStyles((theme) => ({}));

interface NoteRowProps {
    note: Note
    setNotesIterate: React.Dispatch<React.SetStateAction<number>>,
}

const NoteRow: FC<NoteRowProps> = (
    {
        note,
        setNotesIterate
    }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);

    function done(note: Note) {
        NotesService.done(note)
            .then(function (resp) {
                setNotesIterate((c) => c + 1)
            })
            .catch(function (err) {
                console.log(err)
            })
    }

    function change(note: Note) {
        NotesService.change(note)
            .then(function (resp) {
                setNotesIterate((c) => c + 1)
            })
            .catch(function (err) {
                console.log(err)
            })
    }

    return (
        <TableRow key={note.id}>
            <TableCell>
                {note.text}
            </TableCell>
            <TableCell width="10%">
                <ButtonGroup>
                    {note.cardId ? <EditCardButtonContainer cardId={note.cardId} color={"black"}/> : <div/>}
                    <MyButton sx={{mr: 2}} variant="contained"
                              hotkeyEnabled={false}
                              click={() =>
                                  handleOpen()
                    }
                    >edit</MyButton>
                    <EditNoteModalContainer
                        open={open}
                        note={note}
                        setClose={() => {setOpen(false);}}
                        setNotesIterate={setNotesIterate}
                    />
                    <MyButton sx={{mr: 2}} variant="contained"
                              hotkeyEnabled={false}
                              click={() => done(note)}
                    >done</MyButton>

                </ButtonGroup>

            </TableCell>
        </TableRow>

    )
}

export default NoteRow
