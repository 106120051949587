import React, {FC} from "react";
import EditIcon from '@mui/icons-material/Edit';
import {IconButton} from "@mui/material";
import {IAppState} from "../Store";
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {EditCardModalContainer} from "../flashcard/editCard/EditCardModal.component";
import {getCard} from "../flashcard/flashcard.actions";
import {makeStyles} from "@mui/styles";

interface EditCardButtonProps {
    cardId: string,
    getCard: () => void,
    color?: Color
}

const useStyles = makeStyles((theme) => ({
    EditButton: {
        color: "red",
    },
    white: {
        color: 'white',
    },
    black: {
        color: 'black',
    },
}));

export type Color = 'white' | 'black';

const EditCardButton: FC<EditCardButtonProps> = (
    {
        cardId,
        getCard = (): void => {},
        color = "white"
    }) => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    return (
        <React.Fragment>
            <IconButton onClick={() => {
                handleOpen()
            }}>
                <EditIcon
                    className={`${classes[color]} ${classes}`}
                />
            </IconButton>
            <EditCardModalContainer
                open={open}
                cardId={cardId}
                setClose={() => {setOpen(false);}}
                getCard={getCard}
            />
        </React.Fragment>
    )
}

const mapStateToProps = (store: IAppState) => {
    return {
        settings: store.settings.settings,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getCard: getCard
        },
        dispatch
    )


const EditCardButtonContainer = connect(mapStateToProps, mapDispatchToProps)(EditCardButton);

export {
    EditCardButtonContainer
}
