import React, {FC} from "react";
import MyButton from "../../../Button/Button.component";
import {CheckModalContainer} from "./CheckModal.component";
import {SessionFlashCard} from "../../flashcard.type";

interface CheckButtonProps {
    hotkeyOn: boolean,
    card: SessionFlashCard,
    click: Function
}

const CheckButton: FC<CheckButtonProps> = (
    {
        hotkeyOn,
        card,
        click
    }) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);


    function checkTagAndClick() {
        if (card.tags
            .filter(e => e === 'irregular verbs' || e === 'double check')
            .length > 0
        ) {
            handleOpen()
        } else {
            click()
        }
    }

    return (
        <React.Fragment>
            <MyButton sx={{minWidth: 130}}
                      hotkeyEnabled={hotkeyOn}
                      keyName="*"
                      variant="contained"
                      click={checkTagAndClick}
                      color={'success'}
            >Check</MyButton>
            <CheckModalContainer
                open={open}
                card={card}
                click={click}
                setClose={() => {
                    setOpen(false);
                }}
            />
        </React.Fragment>
    )
}

export default CheckButton

