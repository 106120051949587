import React, {FC} from "react";
import {IconButton} from "@mui/material";
import {SessionFlashCard} from "../flashcard/flashcard.type";
import EventNoteIcon from '@mui/icons-material/EventNote';
import {AddNoteModalContainer} from "../notes/button/AddNoteModal.component";

interface NoteCardButtonProps {
    card: SessionFlashCard,
}

const AddNoteButton: FC<NoteCardButtonProps> = (
    {
        card,
    }) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);

    return (
        <React.Fragment>
            <IconButton onClick={() => {
                handleOpen()
            }}>
                <EventNoteIcon color="secondary"/>
            </IconButton>
            <AddNoteModalContainer
                open={open}
                card={card}
                setClose={() => {setOpen(false);}}
            />
        </React.Fragment>
    )
}

export default AddNoteButton
