import http from "../http-common";
import {AddNoteCommand, ChangeNoteCommand, NoteType, Note} from "./note.type";

export class NotesService {
    addNote(command: AddNoteCommand) {
        return http.post("note", command)
    }

    get(type: NoteType) {
        return http.get<Array<Note>>("note?types=" + type)
    }

    done(note: Note) {
        let command = ChangeNoteCommand.create({
            id: note.id,
            text: note.text,
            cardId: note.cardId,
            type: note.type,
            done: true,
        });
        return http.put<Array<Note>>(`note/change`, command)
    }

    change(note: Note) {
        let command = ChangeNoteCommand.create({
            id: note.id,
            text: note.text,
            cardId: note.cardId,
            type: note.type,
            done: false,
        });
        return http.put<Array<Note>>(`note/change`, command)
    }


}

export default new NotesService();