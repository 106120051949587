import React, {FC, useEffect, useState} from "react";
import {Box, Button, Typography} from "@mui/material";
import {NextFlashcardSessionContainer} from "./nextFlashcardSession.component";
import {makeStyles} from "@mui/styles";
import {EnrichSession, SessionDetail, SessionFlashCard, SessionFlashCardsStatus} from "../flashcard.type";
import {IAppState} from "../../Store";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import {getCard} from "../flashcard.actions";
import FlashCardService from "../flashcard.service";

const useStyles = makeStyles((theme) => ({
    FlashcardsSessionStartMenu: {
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontSize: "calc(10px + 2vmin)",
    },
    StartSessionButton: {
        marginTop: 10,
        backgroundColor: "green",
        color: "white"
    }
}));

interface FlashcardsSessionProps {
    getCard: () => void,
    loading: boolean
    card: SessionFlashCard | undefined
    sessionDetail: SessionDetail
}

const FlashcardsSession: FC<FlashcardsSessionProps> = (
    {
        card,
        sessionDetail,
        getCard = (): void => {
        },
    }) => {
    const classes = useStyles();
    const [sessionStarted, setSessionStarted] = useState(false);

    useEffect(() => {
        getCard()
    }, []);

    function enrichSession(status: SessionFlashCardsStatus) {
        let command: EnrichSession;
        command = {
            status: status,
            count: 100
        };
        FlashCardService.enrichSession(command)
            .then(function (resp) {
                getCard()
            })
            .catch(function (err) {
                console.log(err)
            })
    }

    return (
        <div>
            {!sessionStarted ? <div className={classes.FlashcardsSessionStartMenu}>

                <Typography id="modal-modal-title" variant="h6" component="h2">
                    new {sessionDetail?.newCardCount}
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    repeat {sessionDetail?.repeatCardCount}
                </Typography>
                <Box pl={5} pr={5} pt={1}>
                    {(sessionDetail?.repeatCardCount > 0 || sessionDetail?.newCardCount > 0) &&
                        <Button variant="contained"
                                className={classes.StartSessionButton}
                                onClick={() => setSessionStarted(true)}
                                color={'success'}
                        >Start Session with {sessionDetail.repeatCardCount + sessionDetail.newCardCount} cards</Button>}
                    <Button variant="contained"
                            className={classes.StartSessionButton}
                            onClick={() => enrichSession(SessionFlashCardsStatus.NEW_CARD)}
                            color={'success'}
                    >enrich new card</Button>

                    <Button variant="contained"
                            className={classes.StartSessionButton}
                            onClick={() => enrichSession(SessionFlashCardsStatus.REPEAT_CARD)}
                            color={'success'}
                    >repeat new card</Button>
                </Box>


            </div> : <NextFlashcardSessionContainer card={card!!}/>}
        </div>
    )
}
const mapStateToProps = (store: IAppState) => {
        return {
            card: store.card.card?.card,
            loading: store.card.loading,
            sessionDetail: store.card.card?.sessionDetail ?? SessionDetail.create(
                {
                    newCardCount: 0,
                    repeatCardCount: 0,
                    rest: 0
                })
        };
    }
;

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getCard: getCard
        },
        dispatch
    )


const FlashcardsSessionContainer = connect(mapStateToProps, mapDispatchToProps)(FlashcardsSession);

export {
    FlashcardsSessionContainer
}
