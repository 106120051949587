import {ActionCreator, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {Settings} from "./settings.type";
import {SettingsState} from "./settingsReducer";

export enum SettingTypes {
    SET_VOLUME = 'SET_VOLUME',
}

export type SetVolumeAction = {
    type: SettingTypes.SET_VOLUME
    settings: Settings
}

export type SetSettingActions = SetVolumeAction

// export const setVolume: ActionCreator<ThunkAction<Promise<any>, SettingsState, null, SetVolumeAction>> = (volume: boolean) => {
//     return async (
//         dispatch: Dispatch,
//     ) => {
//         try {
//             dispatch({
//                 settings: Settings.create({volume: volume}),
//                 type: SettingTypes.SET_VOLUME,
//             });
//         } catch (err) {
//             console.error(err);
//         }
//     };
// };

//chyba SetVolumeAction powino sie nazywac SetSettingAction
export const changeSetting: ActionCreator<ThunkAction<Promise<any>, SettingsState, null, SetVolumeAction>> = (setting: Settings) => {
    return async (
        dispatch: Dispatch,
    ) => {
        try {
            dispatch({
                settings: setting,
                type: SettingTypes.SET_VOLUME,
            });
        } catch (err) {
            console.error(err);
        }
    };
};

