import {ActionCreator} from 'redux';
import {ThunkAction} from 'redux-thunk';
import FlashCardService from "./flashcard.service";
import {NewCardWithDetailSession, SessionFlashCardsStatus} from "./flashcard.type";
import {IAppState} from "../Store";

export enum SessionFlashCardActionTypes {
    GET_REQUEST = 'GET_REQUEST',
    GET_SUCCESS = 'GET_SUCCESS',
}

export type GetSessionFlashCardAction = {
    type: SessionFlashCardActionTypes.GET_REQUEST
}

export type GetSessionFlashCardActionFinish = {
    type: SessionFlashCardActionTypes.GET_SUCCESS
    card: NewCardWithDetailSession
}

export type SessionFlashCardActions = GetSessionFlashCardAction | GetSessionFlashCardActionFinish

export const getCard: ActionCreator<
    ThunkAction<Promise<any>, IAppState, null, SessionFlashCardActions>
    > = () => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: SessionFlashCardActionTypes.GET_REQUEST,
                loading: true,
            });
            let response
            if (getState().settings.settings.onlyNewCard){
                response = await FlashCardService.getNextCard(SessionFlashCardsStatus.NEW_CARD);
            } else {
                response = await FlashCardService.getNextCard();
            }

            dispatch({
                type: SessionFlashCardActionTypes.GET_SUCCESS,
                loading: false,
                card: response.data,
            });
            return response;
        } catch (err) {
            window.location.reload();
        }
    };
};

