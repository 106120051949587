import React, {FC} from "react";
import {Box, Button, InputLabel, OutlinedInput} from "@mui/material";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import Select from "react-select";
import {connect} from "react-redux";
import {AddNoteCommand, NoteType} from "../note.type";
import NotesService from "../notes.service";
import {SessionFlashCard} from "../../flashcard/flashcard.type";

interface AddNoteFormProps {
    card?: SessionFlashCard,
    close: () => void,
}

interface IFormInput {
    text: string,
    type: { label: string; value: NoteType }
}

const AddNoteForm: FC<AddNoteFormProps> = (
    {
        card,
        close = (): void => {},
    }) => {

    const {control, handleSubmit} = useForm<IFormInput>();


    const onSubmit: SubmitHandler<IFormInput> = data => {
        let cardId
        if (data.type.value === NoteType.HARD_CARD || data.type.value === NoteType.CARD ){
            cardId = card?.id
        }
        const command: AddNoteCommand = {
            cardId: cardId,
            type: data.type.value,
            text: data.text
        };

        NotesService.addNote(command)
            .then(function (resp) {
                close()
            })
            .catch(function (err) {
                console.log(err)
            })

    };


    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="text"
                    control={control}
                    defaultValue={""}
                    render={({field}) =>
                        <Box>
                            <InputLabel htmlFor="outlined-adornment-amount">Text</InputLabel>
                            <OutlinedInput  {...field} fullWidth sx={{m: 1}}/>
                        </Box>}
                />
                <Controller
                    name="type"
                    control={control}
                    defaultValue={{value: NoteType.CARD, label: NoteType.CARD.valueOf()}}
                    render={({field}) =>
                        <Box pt={2} pb={2}>
                            <InputLabel htmlFor="outlined-adornment-amount">TYPE</InputLabel>
                            <Select
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: 'blue',
                                        primary25: '#E9B5B5',
                                        neutral0: 'black',
                                        neutral80: 'white',

                                    },
                                })}
                                isSearchable={false}
                                {...field}
                                options={[
                                    {value: NoteType.CARD, label: NoteType.CARD.valueOf()},
                                    {value: NoteType.HARD_CARD, label: NoteType.HARD_CARD.valueOf()},
                                    {value: NoteType.GRAM, label: NoteType.GRAM.valueOf()},
                                    {value: NoteType.TO_ASK, label: NoteType.TO_ASK.valueOf()},
                                    {value: NoteType.TO_DO, label: NoteType.TO_DO.valueOf()},
                                    {value: NoteType.SIMPLE, label: NoteType.SIMPLE.valueOf()},
                                ]}
                            />
                        </Box>}
                />

                <Button type="submit" variant="contained" color="primary">
                    Edytuj
                </Button>
            </form>
        </React.Fragment>
    )
}


const AddNoteFormContainer = connect()(AddNoteForm);

export {
    AddNoteFormContainer
}
