import {useParams, useSearchParams} from 'react-router-dom';
import React from "react";
import {useTranslation} from "./translation.hook";
import {AddCardFormContainer} from "../flashcard/addCard/AddCardForm.component";

const autoCloseWindow = (ms: number) => setTimeout(() => {
    window.close()
}, ms);


const Translation = () => {
    const {id} = useParams() as {
        id: string;
    }
    const [searchParams] = useSearchParams();
    const side = searchParams.get('side') || 'unknown'
    autoCloseWindow(120_000);
    const translation = useTranslation(id).replace(/["']/g, "")
    return <AddCardFormContainer
        initFront={id}
        initBack={translation}
        from={side}
        tags={["translation"]}
    ></AddCardFormContainer>

}

export default Translation
