import React, {FC} from "react";
import FlashCardService from "../flashcard/flashcard.service";
import {Box, IconButton, Typography} from "@mui/material";
import {SessionFlashCard} from "../flashcard/flashcard.type";
import {makeStyles} from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import {blueGrey} from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
    Modal: {
        position: 'absolute',
        top: '35%',
        left: '30%',
        right: '30%',
        margin: "auto",
        padding: 10,
        backgroundColor: "white",
        border: '2px solid #000',
    },
}));

interface IgnoreCardButtonProps {
    card: SessionFlashCard,
    getCard: () => void,
}

const IgnoreCardButton: FC<IgnoreCardButtonProps> = (
    {
        card,
        getCard
    }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    function ignoreCard(cardId: string) {
        FlashCardService.ignoreCard(cardId)
            .then(function (resp) {
                getCard()
            })
            .catch(function (err) {
                console.log(err)
            })
    }
    return (
        <React.Fragment>
            <IconButton onClick={() => {
                handleOpen()
            }}>
                <CloseIcon color="secondary"/>
            </IconButton>
            <Modal
                open={open}
                onClose={() => {setOpen(false);}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.Modal}>
                    <div>
                        <Typography id="modal-modal-title" variant="h6" component="h2" color={"black"}>
                            Ignore?
                        </Typography>
                        <IconButton onClick={() => {
                            ignoreCard(card.id)
                            handleClose()
                        }}
                        >
                            <ThumbUpAltIcon sx={{color: blueGrey[900]}}/>
                        </IconButton>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    )
}

export default IgnoreCardButton
