import {createTheme} from "@mui/material/styles";

import red from "@mui/material/colors/red";
import green from "@mui/material/colors/green";
import {purple} from "@mui/material/colors";

const theme = createTheme({
    palette: {
        success: green,
        error: red,
        secondary: {
            main: "#ffffff",
        },
        info: purple


        // white: '#ffffff',
    },
});

export default theme;