import React, {FC} from "react";
import {
    Box,
    IconButton,
    Typography
} from "@mui/material";
import {
    SessionDetail,
} from "../flashcard/flashcard.type";
import {makeStyles} from "@mui/styles";

import Modal from "@mui/material/Modal";


const useStyles = makeStyles((theme) => ({
    Modal: {
        position: 'absolute',
        top: '35%',
        left: '30%',
        right: '30%',
        margin: "auto",
        padding: 10,
        backgroundColor: "white",
        border: '2px solid #000',
    },
    Icon: {
        color: "white",
        fontSize: 10
    }
}));

interface StaticButtonProps {
    sessionDetail: SessionDetail,
}

const StaticButton: FC<StaticButtonProps> = (
    {
        sessionDetail,
    }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);

    return (
        <React.Fragment>
            <IconButton onClick={() => {
                handleOpen()
            }}>
                <Typography className={classes.Icon} fontSize={10}>
                    N:{sessionDetail.newCardCount}
                    <br/>
                    R:{sessionDetail.repeatCardCount} / {sessionDetail.rest}
                </Typography>

            </IconButton>
            <Modal
                open={open}
                onClose={() => {setOpen(false);}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.Modal}>

                </Box>
            </Modal>
        </React.Fragment>
    )
}

export default StaticButton
