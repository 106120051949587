import React, {FC} from "react";
import {Box, Typography} from "@mui/material";
import {connect} from 'react-redux';
import Modal from "@mui/material/Modal";
import {makeStyles} from "@mui/styles";
import MyButton from "../../../Button/Button.component";
import {SessionFlashCard} from "../../flashcard.type";

const useStyles = makeStyles((theme) => ({
    Modal: {
        position: 'absolute',
        top: '15%',
        left: '15%',
        right: '15%',
        // margin: "auto",
        padding: 10,
        backgroundColor: "white",
        border: '2px solid #000',
    },
}));

interface CheckModalProps {
    open: boolean,
    card: SessionFlashCard,
    click: Function
    setClose: () => void,
}

const CheckModal: FC<CheckModalProps> = (
    {
        open,
        card,
        click,
        setClose = (): void => {
        },
    }) => {
    const classes = useStyles();


    function close() {
        setClose();
    }

    return (
        <React.Fragment>
            <Modal
                open={open}
                onClose={() => {
                    close()
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.Modal}>
                    <Typography style={{color: 'black'}} >Are you sure? U will check `{card.frontSide}`? </Typography>
                    <MyButton sx={{minWidth: 130}}
                              variant="contained"
                              click={click}
                              color={'success'}
                    >Check</MyButton>
                </Box>
            </Modal>

        </React.Fragment>
    )
}


const CheckModalContainer = connect()(CheckModal);

export {
    CheckModalContainer
}
