import React, {FC, useState} from "react";
import {TableCell, TableRow, TextField,} from "@mui/material";
import {FLashCardCreateCommand, Line} from "../flashcard.type";

import MyButton from "../../Button/Button.component";
import moment from "moment/moment";
import FlashCardService from "../flashcard.service";


interface FlashcardBatchAddCorrectLineProps {
    line: Line
}

const FlashcardBatchAddCorrectLine: FC<FlashcardBatchAddCorrectLineProps> = (
    {
        line,
    }) => {

    const [thisLine, setThisLine] = useState(line);
    const [addDisable, setAddDisable] = useState(isEmptyOrSpaces(line));
    const [hidden, setHidden] = useState(false);

    function deleteLine() {
        setHidden(true)
    }

    function add(line: Line) {
        FlashCardService.addCard(FLashCardCreateCommand.create({
            frontSide: line.frontSide,
            backSide: line.backSide,
            from: "LESSON_" + moment().format("YYYY-MM-DD"),
            tags: line.tags
        })).then(req => {
            deleteLine()
        })
            .catch(e => console.log(e))
    }

    function isEmptyOrSpaces(line: Line) {
        return line.frontSide === null || line.frontSide.match(/^ *$/) !== null ||
            line.backSide === null || line.backSide.match(/^ *$/) !== null;
    }

    return (<React.Fragment>
        {!hidden ?
            <TableRow>
                <TableCell component="th" scope="row" color={"red"}>
                    <TextField
                        onChange={(val) => {
                            const lineCopy = thisLine
                            lineCopy.frontSide = val.target.value
                            setThisLine(lineCopy)
                            setAddDisable(isEmptyOrSpaces(thisLine))

                        }}
                        defaultValue={thisLine.frontSide}
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        onChange={(val) => {
                            const lineCopy = thisLine
                            lineCopy.backSide = val.target.value
                            setThisLine(lineCopy)
                            setAddDisable(isEmptyOrSpaces(thisLine))
                        }}
                        defaultValue={thisLine.backSide}
                    />
                </TableCell>
                <TableCell>
                    <MyButton sx={{mr: 2}} variant="contained"
                              hotkeyEnabled={false}
                              disabled={addDisable}
                              click={() => add(thisLine)}
                    >ADD </MyButton>
                    <MyButton sx={{mr: 2}} variant="contained"
                              hotkeyEnabled={false}
                              color={'error'}
                              click={() => deleteLine()}
                    >Ignore line </MyButton>
                </TableCell>
            </TableRow> : <React.Fragment></React.Fragment>}
    </React.Fragment>)

}

export default FlashcardBatchAddCorrectLine