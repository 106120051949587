import React, {FC} from "react";
import {Box} from "@mui/material";
import {connect} from 'react-redux';
import Modal from "@mui/material/Modal";
import {makeStyles} from "@mui/styles";
import {AddNoteFormContainer} from "./AddNoteForm.component";
import {SessionFlashCard} from "../../flashcard/flashcard.type";

const useStyles = makeStyles((theme) => ({
    Modal: {
        position: 'absolute',
        top: '15%',
        left: '15%',
        right: '15%',
        // margin: "auto",
        padding: 10,
        backgroundColor: "white",
        border: '2px solid #000',
    },
}));

interface AddNoteModalProps {
    open: boolean,
    card?: SessionFlashCard,
    setClose: () => void,
}

const AddNoteModal: FC<AddNoteModalProps> = (
    {
        open,
        card,
        setClose = (): void => {},
    }) => {
    const classes = useStyles();


    function close(){
        setClose();
    }

    return (
        <React.Fragment>
            <Modal
                open={open}
                onClose={() => {
                    close()
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.Modal}>
                    <AddNoteFormContainer
                        card={card}
                        close={() => {close()}}
                    />
                </Box>
            </Modal>

        </React.Fragment>
    )
}


const AddNoteModalContainer = connect()(AddNoteModal);

export {
    AddNoteModalContainer
}
