import { applyMiddleware, combineReducers, createStore} from 'redux';
import {cardReducer, SessionFlashCardState} from "./flashcard/flashcard.reducer";
import {settingsReducer, SettingsState} from "./setting/settingsReducer";
import thunk from 'redux-thunk';

export interface IAppState {
    card: SessionFlashCardState;
    settings: SettingsState;
}

const rootReducer = combineReducers<IAppState>({
    card: cardReducer,
    settings: settingsReducer,
});

// export const store = createStore(
//     rootReducer
// );

export default function configureStore() {
    const store = createStore(rootReducer, undefined, applyMiddleware(thunk));
    return store;
}

