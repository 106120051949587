import React, {FC, useEffect, useState} from "react";
import {Box, Button, InputLabel, OutlinedInput} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {connect} from 'react-redux';
import {FLashCardCreateCommand} from "../flashcard.type";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import FlashCardService from "../flashcard.service";

const useStyles = makeStyles((theme) => ({
    Translation: {
        textAlign: "center",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "calc(10px + 2vmin)",
    },
}));

interface AddCardFormProps {
    initFront: string
    initBack: string
    from: string
    tags: Array<string>
}

interface IFormInput {
    front: string
    back: string
}

const AddCardForm: FC<AddCardFormProps> = (
    {
        initFront,
        initBack,
        from,
        tags
    }) => {
    const {control, reset, handleSubmit} = useForm<IFormInput>();
    const [_initBack] = useState(initBack)

    const classes = useStyles();

    useEffect(() => {
        reset(formValues => ({
            ...formValues,
            back: initBack,
        }));
    }, [initBack]);

    const onSubmit: SubmitHandler<IFormInput> = data => {
        FlashCardService.addCard(
            FLashCardCreateCommand.create({
                frontSide: data.front,
                backSide: data.back,
                from: from,
                tags: tags
            })
        )
            .then(req => window.close())
            .catch(e => window.close())
    };

    return (
            // <Paper style={{width:"100%"}}>
        <div className={classes.Translation}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="front"
                        control={control}
                        defaultValue={initFront}
                        render={({field}) =>
                            <Box>
                                <InputLabel htmlFor="outlined-adornment-amount" style={{color:"white"}}>Front</InputLabel>
                                <OutlinedInput
                                    {...field}
                                    fullWidth sx={{mr: 2, ml:2}}
                                    style={{
                                        width:"85%",
                                        backgroundColor: "white",
                                    }}/>
                            </Box>}
                    />
                    <Controller
                        name="back"
                        control={control}
                        defaultValue={_initBack}
                        render={({field}) =>
                            <Box pt={2}>
                                <InputLabel htmlFor="outlined-adornment-amount" style={{color:"white"}}>Back</InputLabel>
                                <OutlinedInput
                                    {...field}
                                    fullWidth sx={{mr: 2, ml:2}}
                                    style={{
                                        width:"85%",
                                        backgroundColor: "white",
                                    }}/>
                            </Box>}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{marginRight: 50, marginTop:10}}
                    >Add</Button>
                    <Button variant="contained"
                            onClick={() => window.close()}
                            style={{
                                marginTop:10,
                                backgroundColor: "red",
                                color: "white"
                            }}
                    >Close</Button>
                </form>
        </div>
    )
}


const AddCardFormContainer = connect()(AddCardForm);

export {
    AddCardFormContainer
}
