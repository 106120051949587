import React, {FC} from "react";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from "@mui/material";
import {Line} from "../flashcard.type";
import FlashcardBatchAddCorrectLine from "./flashcardBatchAddCorrectLine.component";

interface FlashcardBatchAddCorrectLinesProps {
    lines: Array<Line>
}

const FlashcardBatchAddCorrectLines: FC<FlashcardBatchAddCorrectLinesProps> = (
    {
        lines,
    }) => {

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ANG</TableCell>
                        <TableCell>POL</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {lines.map((line, idx) => (<FlashcardBatchAddCorrectLine line={line} key={idx} />))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default FlashcardBatchAddCorrectLines

