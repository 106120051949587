import React, {FC} from "react";
import {Box} from "@mui/material";
import {connect} from 'react-redux';
import Modal from "@mui/material/Modal";
import {makeStyles} from "@mui/styles";
import {Note} from "../note.type";
import {EditNoteFormContainer} from "./EditNoteForm.component";

const useStyles = makeStyles((theme) => ({
    Modal: {
        position: 'absolute',
        top: '15%',
        left: '15%',
        right: '15%',
        // margin: "auto",
        padding: 10,
        backgroundColor: "white",
        border: '2px solid #000',
    },
}));

interface EditNoteModalProps {
    open: boolean,
    note: Note,
    setClose: () => void,
    setNotesIterate: React.Dispatch<React.SetStateAction<number>>,
}

const EditNoteModal: FC<EditNoteModalProps> = (
    {
        open,
        note,
        setClose = (): void => {},
        setNotesIterate
    }) => {
    const classes = useStyles();


    function close(){
        setClose();
    }

    return (
        <React.Fragment>
            <Modal
                open={open}
                onClose={() => {
                    close()
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.Modal}>
                    <EditNoteFormContainer
                        note={note}
                        close={() => {close()}}
                        setNotesIterate={setNotesIterate}
                    />
                </Box>
            </Modal>

        </React.Fragment>
    )
}


const EditNoteModalContainer = connect()(EditNoteModal);

export {
    EditNoteModalContainer
}
