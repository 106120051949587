import {makeStyles} from "@mui/styles";


const useStyles = makeStyles((theme) => ({
    Home: {
        textAlign: "center",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        fontSize: "calc(10px + 2vmin)",
        display: "flex",
    },
}));

const Home = () => {
    const classes = useStyles();
    return (
        <div className={classes.Home}>
            <p>CICHON APP</p>
            <p/>
            <p>VERSION: {process.env.REACT_APP_GIT_SHA}</p>
        </div>
    )
}
export default Home