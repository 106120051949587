import React, {useState} from "react";
import FlashCardService from "../flashcard.service";
import {
    Grid,
    Input,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import {FLashCardCreateCommand, Line, RawLine, RawLineWithTags} from "../flashcard.type";
import {makeStyles} from "@mui/styles";
import MyButton from "../../Button/Button.component";
import FlashcardBatchAddCorrectLines from "./flashcardBatchAddCorrectLines.component";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    FlashcardBatchAdd: {
        textAlign: "center",
        // minHeight: "160vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // justifyContent: "center",
        fontSize: "calc(10px + 2vmin)",
    },
    TextFieldBatchAdd: {
        backgroundColor: "white",
        width: 1000,
    },
    Input: {
        backgroundColor: "red",
        padding: 2
    },
    Paper: {
        backgroundColor: "white",
        width: 1000,
        fontSize: 15,
        whiteSpace: "pre-line"
    },
    Typography: {
        backgroundColor: "white",
        width: 1000,
        fontSize: 15,
        whiteSpace: "pre-line",
        color: "red"
    }


}));

const FlashcardBatchAdd = () => {
    const classes = useStyles();
    const [wrongLines, setWrongLines] = useState(new Array<RawLine>());
    const [probablyCorrectLines, setProbablyCorrectLines] = useState(new Array<Line>());

    function rawLineToLine(raw: RawLine): Line {
        let rawLineWithTags: RawLineWithTags = getTags(raw);
        let splitLine = rawLineWithTags.text
            .split("-")
            .map((it) => deleteFirstWrongChars(it))
            .map((it) => it.replace("~", "–"))

        let frontSide = splitLine[0]
        splitLine.shift()
        let backSide = splitLine.join("!-!")

        return {
            id: rawLineWithTags.id,
            frontSide: frontSide,
            backSide: backSide,
            tags: rawLineWithTags.tags
        };
    }

    function addToProbablyCorrectLines(raw: RawLine) {
        if (raw.text.split("-").length === 2) {
            const newProbablyCorrectLines = [...probablyCorrectLines, rawLineToLine(raw)]
            deleteWrongLine(raw)
            setProbablyCorrectLines(newProbablyCorrectLines)
        } else {
            alert("alert")
        }
    }

    function deleteWrongLine(raw: RawLine) {
        let lines2 = wrongLines.filter(obj => obj !== raw);
        setWrongLines(lines2)
    }

    function deleteFirstWrongChars(side: string) {
        while (!/[a-zA-Z0-9$(]/.test(side.charAt(0))) {
            side = side.substring(1);
        }
        return side
    }

    function getTags(line: RawLine): RawLineWithTags {
        let newText: string = line.text;
        const tags: string[] = [];
        while (newText.includes("<") && newText.includes(">")) {
            let tag = newText.substring(
                newText.indexOf("<"),
                newText.indexOf(">") + 1
            );
            tags.push(
                tag
            )
            newText = newText.replace(tag, "")
        }
        return {
            id: line.id,
            text: newText,
            tags: tags
        }
    }

    function deleteEmoji(txt: String) {
        return txt.replace(":-)", "")
            .replace(":-)", "")
            .replace(":)", "")
            .replace(":-(", "")
            .replace(":(", "")
            .replace(":-/", "")
            .replace(":/", "")
            .replace(";-)", "")
            .replace(";)", "")

    }

    function deleteWhitespace(txt: String) {
        return txt.replace(/\s/g, "")
    }

    function deleteFistThinker(txt: String) {
        if (txt.charAt(0) === '-') {
            return txt.slice(1)
        }
        return txt
    }

    const handleSubmit = (evt: { preventDefault: () => void; }) => {
        evt.preventDefault();
        probablyCorrectLines.forEach(it =>
            FlashCardService.addCard(FLashCardCreateCommand.create({
                frontSide: it.frontSide,
                backSide: it.backSide,
                from: "LESSON_" + moment().format("YYYY-MM-DD"),
                tags: it.tags
            }))
                .then(req => window.close())
                .catch(e => window.close()))
    }
    return (
        <div className={classes.FlashcardBatchAdd}>
            <form onSubmit={handleSubmit}>
                <Grid container direction={"column"}>
                    {probablyCorrectLines.length === 0 && wrongLines.length === 0 && <Grid item xs={1}>
                        <TextField className={classes.TextFieldBatchAdd}
                                   multiline
                                   rows={25}
                                   value={""}
                                   onChange={e => {
                                       let rawLines = e.target.value
                                           .split(/\r?\n/)
                                           .map((it) => it.toLowerCase())
                                           .map((it) => it.trim())
                                           .map((it) => deleteFistThinker(it))
                                           .filter((it) => it.split('').filter(char => /[a-zA-Z]/.test(char)).length > 0) //odfiltruj linie bez znaków
                                           .filter((it) => !deleteWhitespace(it).includes("--")) //odfiltruj linie bez znaków
                                           .map((it, idx) => {
                                               const command: RawLine = {
                                                   id: idx,
                                                   text: deleteEmoji(it)
                                               }

                                               return command
                                           })


                                       let lines = rawLines.map((it) => rawLineToLine(it));


                                       lines
                                           .forEach((it) => setProbablyCorrectLines(oldArray => [...oldArray, it]))
                                   }}
                        />
                    </Grid>}
                    {probablyCorrectLines.length > 0 && <React.Fragment>
                        <FlashcardBatchAddCorrectLines lines={probablyCorrectLines}/>
                        <Grid item xs={1} mt={2}>
                            <Input
                                className={classes.Input}
                                type="submit"
                                value="Wyslij"
                            />
                        </Grid>
                    </React.Fragment>}
                </Grid>
            </form>
            {wrongLines.length > 0 && <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>text {wrongLines.length}</TableCell>
                            <TableCell>Ignore</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {wrongLines.map((wrongLine, idx) => (
                            <TableRow key={idx + wrongLine.text}>
                                <TableCell component="th" scope="row" color={"red"}>
                                    <TextField
                                        defaultValue={wrongLine.text}
                                        fullWidth={true}
                                        onChange={(val) => {
                                            const linesCopy = wrongLines
                                            let index = linesCopy.findIndex((obj => obj.id === wrongLine.id));
                                            linesCopy[index].text = val.target.value
                                            setWrongLines(linesCopy)
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <MyButton sx={{mr: 2}} variant="contained"
                                        // disabled={(wrongLine.text.split("-").length !== 2)} TODO fix rerendering
                                              hotkeyEnabled={false}
                                              click={() => addToProbablyCorrectLines(wrongLine)}
                                    >Try Add </MyButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>}
        </div>
    )
}

export default FlashcardBatchAdd
