import http from "../http-common";
import {TranslateCommand, TranslationData,} from "./translation.type";

class TranslationService {

    translate(command: TranslateCommand) {
        return http.put<TranslationData>("translate", command)
    }

}

export default new TranslationService();