import {useEffect, useState} from "react";
import TranslationService from "./translation.service";
import {TranslateCommand,} from "./translation.type";

export function useTranslation(text: string, targetLang: string = 'pl') {
    const [translation, setTranslation] = useState<string>('');
    const command: TranslateCommand = {
        text: text,
        targetLang: targetLang
    };
    useEffect(() => {
        const fetchData = async () => {
            const result = await TranslationService.translate(command)
            setTranslation(result.data.targetSentence);
        };
        fetchData();
    }, []);
    return translation;
}