import React, {FC, useState} from "react";
import {Button as But, ButtonProps} from "@mui/material";
import Hotkeys from "react-hot-keys";

interface MyButtonProps extends ButtonProps {
    hotkeyEnabled?: Boolean
    keyName?: string;
    click: Function;
    unDisabledDelay?: number;
    disable?: boolean;
    style?: React.CSSProperties
}

const MyButton: FC<MyButtonProps> = (
    {
        hotkeyEnabled = false,
        keyName = "*",
        click,
        children,
        unDisabledDelay = 0,
        disable = false,
        style,
        ...rest
    }) => {

    const [myDisabled, setMyDisabled] = useState(() => unDisabledDelay > 0 || disable)

    if (unDisabledDelay > 0) {
        setTimeout(() => {
                setMyDisabled(false)
            },
            unDisabledDelay);
    }

    return (

        <But
            style={style}
            onClick={() => click()}
            disabled={myDisabled}
            {...rest}
        >
            <Hotkeys
                disabled={!hotkeyEnabled}
                keyName={keyName}
                onKeyDown={() => click()}
                filter={() => true}
            />
            <div>
                {children}
            </div>
        </But>

    );
}


export default MyButton